import React, { createContext, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {

  const showToast = (message, type, option = {}) => {
    if(type === "error") {
      toast.error(message, option);
    }
    else {
      toast(message, option);
    }
  }

  return (
    <ToastContext.Provider
      value={{
        showToast
      }}
    >
      <ToastContainer />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
