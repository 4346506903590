import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import Loadercustom from "../Component/Loader/Loadercustom";

const Cpawebpage = lazy(() => import("../Pages/Cpawebpage"));
const ThankYou = lazy(() => import("../Pages/ThankYou"));
const Home = lazy(() => import("../Pages/Home"));

function AppRoutes() {
    return (
        <Suspense fallback={<Loadercustom/>}>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/ccpa-form" element={<Cpawebpage />} />
                    <Route path="/thank-you" element={<ThankYou />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Router>
        </Suspense>
    );
}

export default AppRoutes;
