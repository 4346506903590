import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './Routes/AppRoutes';
import { ToastProvider } from './context/ToastProvider';
import { useEffect, useState } from 'react';
import Loadercustom from './Component/Loader/Loadercustom';
function App() {
  const [loadForm, setLoadForm] = useState(false)
  useEffect(() => {
    localStorage.setItem('showForm', "true");
    setTimeout(() => {
      if((localStorage.getItem('showForm')) == "true") {
        if(document.getElementById("omForm")) {
          document.getElementById("omForm").style.display = "none";
        }
      }
      setLoadForm(true);
    }, 300);
  }, [])
  return (
    <ToastProvider>
      {loadForm ? <AppRoutes/> : <Loadercustom />}
    </ToastProvider>
  );
}

export default App;
